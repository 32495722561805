import React, { useRef, useEffect, useState } from 'react';
import { BsFillLightningChargeFill, BsStars, BsChevronDown } from 'react-icons/bs';
import { FaMagic } from 'react-icons/fa';

function ModelSelector({buttons, selected, setSelected}) {
    const [openDropdown, setOpenDropdown] = useState(null);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState(null);
    const dropdownRef = useRef(null);

    const toggleDropdown = (index) => {
        if (openDropdown === index) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(index);
        }
    };

    const handleDropdownSelect = (value, buttonValue) => {
        setSelectedDropdownValue(value);
        setSelected(buttonValue);
        toggleDropdown(null);
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          // Clicked outside of the dropdown menu
          toggleDropdown(null);
        }
      };

    const selectedModelObj = buttons.find(button => {
        if (button.dropdownOptions) {
            const selectedOption = button.dropdownOptions.find(option => option.value === selected);
            if(selected === "dall-e") {
                selected = "dall-e-3";
            } else if(selected === "gpt") {
                selected = "gpt-4o";
            } else {
                return selectedOption !== undefined;
            }
        }
        return button.value === selected;
    }) || {};

    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('click', handleClickOutside);
        
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    return (
        <span ref={dropdownRef} className="isolate inline-flex rounded-md shadow-sm">
            {buttons.map((button, index) => {
                const isSelected = selected.includes(button.value);
                const isSelectedSub = selected === button.value;
                const isHidden = false;// !isSelected && button.value === "GPT4_32K" || !isSelected && button.value === "GPT4" && selected === "GPT4_32K";
                const classes = "relative inline-flex items-center  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" + (isSelected ? " bg-gray-200 " : " bg-white ") + (isHidden ? " hidden " : "");
                const classesSub = "group flex flex-col w-full items-left text-left text-xs leading-3 px-2 py-2 text-sm text-gray-900 hover:bg-gray-100 hover:text-gray-900 ";
                const roundedClass = index === 0 ? 'rounded-l-md' : (index === buttons.length - 1 ? 'rounded-r-md' : '-ml-px');
                return (
                    <div className="relative" key={index}>
                        <button
                            type="button"
                            className={`${classes} ${roundedClass}`}
                            onClick={() => {
                                    if(!selected.includes(button.value)){
                                        setSelected(button.value)
                                    }
                                    toggleDropdown(index);
                                }
                            
                            }
                        >


                            {button.icon}
                            &nbsp;{button.name}&nbsp;
                            {button.dropdown && <BsChevronDown size='1em' />}
                        </button>
                            {button.dropdown && openDropdown === index && (
                                <div className="absolute z-50 bg-white left-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                    <div className="px-1 py-1 ">
                                    {button.dropdownOptions?.map((option, optionIndex) => {
                                    // Generate the className string
                                    let buttonClasses = classesSub;
                                    if (option.value === selected) {
                                        buttonClasses += " bg-gray-200 ";
                                    }

                                    return (
                                        <button
                                            key={optionIndex}
                                            className={buttonClasses}
                                            onClick={() => {
                                                setSelected(option.value);
                                                toggleDropdown(null);
                                            }}
                                        >
                                            
                                            <p className="flex w-full items-center">{option.icon}&nbsp;{option.name}</p>
                                            <p className="leading-3 mt-2 italic">{option.desc}</p>
                                        </button>
                                    )
                                })}

                                </div>
                            </div>
                        )}

                    </div>
                );
            })}
        </span>
    );
}

export default ModelSelector;
