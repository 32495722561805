import logo from './logo.svg';
import './App.css';

import Home from './pages/home';
import Temp from './pages/temp';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./security/authConfig";

const pca = new PublicClientApplication(msalConfig);

// initiate the login flow
// pca.loginRedirect();

function App() {

  return (
    <BrowserRouter>
      <MsalProvider instance={pca}>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />

          </Route>
        </Routes>
      </MsalProvider>
    </BrowserRouter>
  );
}

export default App;
