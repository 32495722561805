import React, { useEffect, useState } from "react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import {  useMsal,  AuthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";


function ProtectedComponent() {
    const { instance, inProgress, accounts } = useMsal();
    const [apiData, setApiData] = useState(null);
    console.log("protected");
    useEffect(() => {
        const accessTokenRequest = {
            scopes: ["user.read"],
            account: accounts[0],
        };
        if (!apiData && inProgress === InteractionStatus.None) {
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    // Acquire token silent success
                    let accessToken = accessTokenResponse.accessToken;
                    // Call your API with token
                    console.log("ok");
                    console.log(accessToken);
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect(accessTokenRequest);
                    }
                    console.log(error);
                });
        }
    }, [instance, accounts, inProgress, apiData]);

    return <p>Return your protected content here: </p>;
}

function Temp() {

    const { login, result, error } = useMsalAuthentication("redirect");

    return (
        <AuthenticatedTemplate>
            <ProtectedComponent />
        </AuthenticatedTemplate>
    );

}



export default Temp;
