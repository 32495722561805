import React, { useState, useEffect } from 'react';
const mulesoftHost = window._env_.REACT_APP_MULE_URL

export function SetLocalStorageAccountDetails(newAccountId, newUsername) {
    localStorage.setItem("localAccountId", newAccountId);
    localStorage.setItem("username", newUsername.toLowerCase());
}

export function GetLocalStorageAccountDetails(key) {
    return localStorage.getItem(key);
}

export function GetGuidelinesForUse() {
    const [data, setData] = useState("");
    useEffect(() => {
        const fetchData = async() => {
            const response = await fetch(mulesoftHost + '/chatgpt-consent/article', {
                method: 'GET'
            });
            const jsonData = await response.json();
            setData(jsonData);
            const modalsWithButtonsToEnable = ["viewGuidelinesForUseModal", "promptedGuidelinesForUseModal", "declinedGuidelinesForUseModal"];
            for(let i = 0; i < modalsWithButtonsToEnable.length; i++){
                const modalWithButtonsToEnable = document.getElementById(modalsWithButtonsToEnable[i]);
                if(modalWithButtonsToEnable) {
                    let buttons = modalWithButtonsToEnable.getElementsByClassName("modal-button");
                    for(let j = 0; j < buttons.length; j++) {
                        const button = buttons[j];
                        button.style.display = "block";
                    }
                }
            }
        };
        fetchData();
    }, []);
    return data;
}


export function GetConsent() {
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async() => {
            const localAccountId = GetLocalStorageAccountDetails("localAccountId");
            const response = await fetch(mulesoftHost + '/chatgpt-consent/' + localAccountId, {
                method: 'GET'
            });
            const jsonData = await response.json();
            setData(jsonData);
            const didConsent = jsonData;
            const promptedGuidelinesForUseModal = document.getElementById("promptedGuidelinesForUseModal");
            if (promptedGuidelinesForUseModal) {
                if (didConsent) {
                    promptedGuidelinesForUseModal.style.display = "hidden";
                } else {
                    promptedGuidelinesForUseModal.style.display = "block";
                }
            }
        };
        fetchData();
    }, []);
    return data;
}

export function SetConsent() {
    const localAccountId = GetLocalStorageAccountDetails("localAccountId");
    const username = GetLocalStorageAccountDetails("username");
    fetch(mulesoftHost + '/chatgpt-consent/' + localAccountId, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({OID: localAccountId, Email: username})
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error("There was an error getting consent information!");
            console.error(error);
        });
}