// Config object to be passed to Msal on creation

export const msalConfig = {
    auth: {
        clientId: window._env_.REACT_APP_AAD_CLIENT_ID,
        authority: window._env_.REACT_APP_AAD_AUTHORITY,
        redirectUri: window._env_.REACT_APP_AAD_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};



// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

const authConfig = {
    dev: {
        domain: 'g3-qa.us.authz.cloudentity.io', // e.g. 'mytenant.us.authz.cloudentity.io.' Recommended; always generates URLs with 'https' protocol.
        // baseUrl: optional alternative to 'domain.' Protocol required, e.g. 'https://mytenant.us.authz.cloudentity.io'
        // In situations where protocol may dynamically resolve to 'http' rather than 'https' (for example in dev mode), use 'baseUrl' rather than 'domain'.
        tenantId: 'g3-qa', // This is generally in the subdomain of your Cloudentity ACP URL
        authorizationServerId: 'chatg3pt', // This is generally the name of the workspace you created the OAuth application in.
        clientId: '12dcb7d3df7648cba9395d2b29302ba8',
        redirectUri: 'http://localhost:3000/',
        scopes: ['profile', 'email', 'openid', 'revoke_tokens'], // 'revoke_tokens' scope must be present for 'logout' action to revoke token! Without it, token will only be deleted from browser's local storage.
        responseType: ['code'],
        idTokenName: "g3-qa_id_token",
        accessTokenName: "g3-qa_access_token",
        setIdToken: true
    },
    test: {
        domain: 'g3-qa.us.authz.cloudentity.io', // e.g. 'mytenant.us.authz.cloudentity.io.' Recommended; always generates URLs with 'https' protocol.
        // baseUrl: optional alternative to 'domain.' Protocol required, e.g. 'https://mytenant.us.authz.cloudentity.io'
        // In situations where protocol may dynamically resolve to 'http' rather than 'https' (for example in dev mode), use 'baseUrl' rather than 'domain'.
        tenantId: 'g3-test', // This is generally in the subdomain of your Cloudentity ACP URL
        authorizationServerId: 'chatg3pt', // This is generally the name of the workspace you created the OAuth application in.
        clientId: '12dcb7d3df7648cba9395d2b29302ba8',
        redirectUri: 'http://localhost:3000/',
        scopes: ['profile', 'email', 'openid', 'revoke_tokens'], // 'revoke_tokens' scope must be present for 'logout' action to revoke token! Without it, token will only be deleted from browser's local storage.
        responseType: ['code'],
        idTokenName: "g3-test_id_token",
        accessTokenName: "g3-test_access_token",
        setIdToken: true
    },
    prd: {
        domain: 'g3-qa.us.authz.cloudentity.io', // e.g. 'mytenant.us.authz.cloudentity.io.' Recommended; always generates URLs with 'https' protocol.
        // baseUrl: optional alternative to 'domain.' Protocol required, e.g. 'https://mytenant.us.authz.cloudentity.io'
        // In situations where protocol may dynamically resolve to 'http' rather than 'https' (for example in dev mode), use 'baseUrl' rather than 'domain'.
        tenantId: 'g3-prd', // This is generally in the subdomain of your Cloudentity ACP URL
        authorizationServerId: 'chatg3pt', // This is generally the name of the workspace you created the OAuth application in.
        clientId: '12dcb7d3df7648cba9395d2b29302ba8',
        redirectUri: 'http://localhost:3000/',
        scopes: ['profile', 'email', 'openid', 'revoke_tokens'], // 'revoke_tokens' scope must be present for 'logout' action to revoke token! Without it, token will only be deleted from browser's local storage.
        responseType: ['code'],
        idTokenName: "g3-prd_id_token",
        accessTokenName: "g3-prd_access_token",
        setIdToken: true
    }
};

export default authConfig;