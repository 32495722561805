import React, { useEffect, useState, useRef, useCallback } from "react";
import { Markup } from 'interweave';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import './home.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Clipboard from 'clipboard';
import gfm from 'remark-gfm';
import { FiPlus, FiMessageSquare, FiSend, FiLoader, FiCopy } from 'react-icons/fi';
import { FaRegEdit, FaRegTrashAlt, FaCheck, FaTimes, FaArrowDown, FaPrint, FaMicrophone, FaStop, FaMagic, FaPaintBrush } from 'react-icons/fa';
import { BsImage, BsMicrosoftTeams, BsFillLightningChargeFill, BsStars, BsFillArrowDownCircleFill, BsArrowDownCircle } from 'react-icons/bs';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { CiBrightnessDown } from 'react-icons/ci';
import { RiFileExcel2Line } from 'react-icons/ri';
import { SiMicrosoftpowerpoint } from 'react-icons/si';
import { HiOutlineLightningBolt, HiOutlineMail } from 'react-icons/hi';

import { IoWarningOutline } from 'react-icons/io5';
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal, AuthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";

import rehypeHighlight from 'rehype-highlight'

import botAvatar from '../img/openai-avatar.png';
import userAvatar from '../img/user-avatar.png';

import ModelSelector from '../components/ModelSelector';

import html2pdf from 'html2pdf.js';

import classNames from 'classnames';

import './github-dracula.css';

import { SSE } from 'sse.js';

import TableToExcel from "@linways/table-to-excel";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useDownloadExcel } from 'react-export-table-to-excel';


import { read, utils, writeFileXLSX } from 'xlsx';
import pptxgen from "pptxgenjs";



import { v4 as uuidv4 } from 'uuid';

import {
  GetGuidelinesForUse,
  GetConsent,
  SetConsent,
  SetLocalStorageAccountDetails,
  GetLocalStorageAccountDetails
} from "../components/Consent";

import { encode, decode } from 'gpt-token-utils'


const GPT35_DESCRIPTION = "ChatGPT 3.5 Turbo is great for most everyday tasks.";
const GPT432K_DESCRIPTION = "GPT-4 32k supports 4x more context in your conversation. Great for tasks that require a large amount of text.";
const GPT4O_DESCRIPTION = "Our most capable model, great for tasks that require creativity and advanced reasoning.";
const DALLE3_DESCRIPTION = "DALL-E 3 is our most capable image generation model that can generate images with multiple objects and complex scenes.";

function PlaceholderModalContent() {
  return (
      <div style={{width: '800px'}}>
        <div style={{paddingBottom: '100px'}}>
          <h3><strong><u>Guidelines for Using Artificial Intelligence (“AI”) such as MS ChatGPT</u><p/></strong></h3>
        </div>
        <div className="linear-background">
          <div className="inter-draw"></div>
          <div className="inter-crop"></div>
          <div className="inter-right--top"></div>
          <div className="inter-right--middle"></div>
          <div className="inter-right--bottom"></div>
        </div>
      </div>
  );
}

function isJSONObject (jsonString){
  try {
    let o = JSON.parse(jsonString);
    return o && typeof o === "object";
  }
  catch (e) { }

  return false;
}

function DisableTabbingOutsideOfConsentModals() {
  const modals = ["promptedGuidelinesForUseModal", "declinedGuidelinesForUseModal"];
  for(let i = 0; i < modals.length; i++)
  {
    const modalId = modals[i];
    const modal = document.getElementById(modalId);
    if(modal) {
      let focusableElements = modal.querySelectorAll('a[href], button:not([disabled]), textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select');
      let firstFocusableElement = focusableElements[0];
      let lastFocusableElement = focusableElements[focusableElements.length - 1];
      firstFocusableElement.focus();
      modal.addEventListener('keydown', (e) => {
        if (e.key === 'Tab') {
          if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
              e.preventDefault();
              lastFocusableElement.focus();
            }
          } else {
            if (document.activeElement === lastFocusableElement) {
              e.preventDefault();
              firstFocusableElement.focus();
            }
          }
        }
      });
    }
  }
}

function updateAppNameSpan(selectedModel) {
  const appNameSpan = document.querySelector('.appNameSpan');
  const modelDescriptionSpan = document.querySelector('.modelDescriptionSpan');
  if (appNameSpan) {
    if (["gpt", "gpt-4o"].includes(selectedModel)) {
      appNameSpan.textContent = 'ChatGPT 4-o' + environmentTitle;
        modelDescriptionSpan.textContent = GPT4O_DESCRIPTION;
    } else if (selectedModel === "gpt-4-32k") {
      appNameSpan.textContent = 'ChatGPT 4 32k' + environmentTitle;
      modelDescriptionSpan.textContent = GPT432K_DESCRIPTION;
    } else if (selectedModel === "gpt-35-turbo") {
      appNameSpan.textContent = 'ChatGPT 3.5 Turbo' + environmentTitle;
      modelDescriptionSpan.textContent = GPT35_DESCRIPTION;
    } else if (["dalle", "dall-e-3"].includes(selectedModel)) {
      appNameSpan.textContent = 'DALL-E 3' + environmentTitle;
      modelDescriptionSpan.textContent = DALLE3_DESCRIPTION;
    }
  }
}

// Setup the title of the app to properly reflect the environment
const environmentName = window._env_.REACT_APP_ENVIRONMENT_NAME
let appTitle = "";
let environmentTitle;
if(environmentName !== "PRD") {
  environmentTitle = " (" + environmentName + ")";
}
appTitle = "ChatGPT" + environmentTitle;

// Setup the Mule app endpoint, defaulting to ChatGPT
let nsEndpoint;

const useTypingEffect = (text, delay, role, endOfMessageRef, isAtBottom, noEffect) => {
  const [displayedText, setDisplayedText] = useState(role == 'assistant' && !noEffect ? '' : text);
  const [currentIndex, setCurrentIndex] = useState(role == 'assistant' && !noEffect ? 0 : text.length);
  const [showCursor, setShowCursor] = useState(false);

  const debouncedScroll = useDebounce(() => {
    if (isAtBottom) {
      endOfMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, 100, [endOfMessageRef, isAtBottom]); // Adjust the debounce delay as needed

  let interval;
  useEffect(() => {
    if (role != "assistant" || noEffect) return;


    interval = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 3);

      if (currentIndex >= text.length+3) {
        clearInterval(interval);
        return;
      }
    }, delay);

    return () => {
      clearInterval(interval);
    }
  }, [text, delay, role]);

  useEffect(() => {
    let showC = false;
    if (currentIndex >= text.length+3) {
      clearInterval(interval);
      return;
    }

    if (currentIndex < text.length - 3 && role === "assistant" && !noEffect) {
      showC = true;
    }else{
      showC = false;
    }
    setDisplayedText(text.substring(0, currentIndex) + (showC ? '\u275A' : ''));
    if (role != "assistant" || noEffect) return; // only use debouncedScroll for bot messages
    if (currentIndex <= text.length) {
      debouncedScroll();
    }
  }, [currentIndex]);

  return displayedText;
};



const isNumericalList = (text) => {
  const lines = text.split('\n');
  return lines.every((line) => /^\d+\.\s.*$/.test(line));
};

/*const formatNumericalList = (response) => {
  const lines = response.split('\n');
  const formattedLines = lines.map((line) => {
    const match = line.match(/^\d+\.\s(.*)$/);
    if (match) {
      return `<li>${match[1]}</li>`;
    }
    return line;
  });
  return `<ol>${formattedLines.join('')}</ol>`;
};*/

const Messages = ({ messages, isLoading, endOfMessageRef, promptSubmitted,isAtBottom, currentModel }) => {
  const lastMessageRef = useRef(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block:'end' });
    }
  }, [messages]);

  return (
      <div className="messages">
        {messages.map((message, index) => {
          const isLastMessage = index === messages.length - 1;

          return (
              <React.Fragment key={index}>
                <Message
                    content={message.content}
                    role={message.role}
                    ref={isLastMessage ? lastMessageRef : null}
                    endOfMessageRef={endOfMessageRef}
                    applyTypingEffect={index === messages.length - 1}
                    promptSubmitted={promptSubmitted}
                    isAtBottom={isAtBottom}
                    currentModel={currentModel}
                />
                {isLoading && isLastMessage && (
                    <div className="loading-message"><img src="typing-clear.gif" style={{ height: '32px' }} /></div>
                )}
              </React.Fragment>
          );
        })}
      </div>
  );
};

const Message = React.forwardRef(({ content, role, endOfMessageRef, applyTypingEffect, promptSubmitted, isAtBottom, currentModel }, ref) => {
  const className = role === 'assistant' ? 'message bot' : 'message user';
  const hasCodeBlock = false;
  let noEffect = !applyTypingEffect || !promptSubmitted || hasCodeBlock;
  let typingEffectText = useTypingEffect(content, 0, role, endOfMessageRef, isAtBottom,noEffect);

  const isURL = (content) => {
    try {
      new URL(content);
      return true;
    } catch (error) {
      return false;
    }
  };
  const isContentURL = isURL(content);

  const avatar = role === 'assistant' ? botAvatar : userAvatar;
  const isList = isNumericalList(content);
  const showBreaks = role === 'assistant' ? false : true;
  const formattedContent = typingEffectText.split('\n').map((line, index) => (
    <p key={index}>{line}</p>
  ));

  let DALLEURLS = "";
  if (["dall-e", "dall-e-3"].includes(currentModel)) {
    DALLEURLS = content.split("\n"); // split the string by line breaks
    DALLEURLS.pop(); // remove the last element from the array

  }

  return (
    <div className={className} ref={ref}>

      <div className="message-content">
        <div className="avatar-div">
          <img align="middle" className="avatar" style={{ borderRadius: 3, height: 36, width: 36, marginRight: 20 }} src={avatar} alt="avatar" />
        </div>
        <div className="content-div">
          {hasCodeBlock ? (
              <div/>
          ) : currentModel === "dall-e-3" && isContentURL ? (
              <div className="DALLEContainer">
                {DALLEURLS.map((contentURL) => {
                  if (contentURL === "") {
                    contentURL = 'blank.png';
                  }
                  return (
                <div className="dalleImage" key={contentURL}>
                  <img className="w-25" src={contentURL} />
                  <div className="flex space-x-4">
                    <a target="_blank" title="Download Image" href={contentURL} download className="flex align-middle">
                      <button className="w-fit rounded-md text-black border border-solid border-slate-400 flex leading-4 mt-4
                      "> <BsArrowDownCircle size='1em' /></button>
                    </a>
                    <a target="_blank" title="Share Image in new email message" href={`mailto:?subject=Image%20Download&body="${encodeURIComponent(contentURL)}"`}>
                      <button className="w-fit rounded-md text-black border border-solid border-slate-400 flex leading-4 mt-4">
                        <HiOutlineMail size='1em' />
                      </button>
                    </a>
                  </div>
                </div>
                  )})}
              </div>

          ) : (
              <ReactMarkdown
                  remarkPlugins={[gfm]}
                  children={typingEffectText}
                  className="markdown"
                  breaks={showBreaks}
                  components={{
                    code({ node, inline, className, children, ...props }) {
                      const match = /language-(\w+)/.exec(className || '');
                      let language = "";
                      if(match){
                        language = match[1];
                      }
                      return !inline ? (
                          <CodeBlock language={language} value={children} {...props} />
                      ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                      );
                    },
                    table({ node, inline, className, children, ...props }) {

                      return (
                          <TableBlock children={children} className={className} {...props} ></TableBlock>
                          /*
                          <div>
                            <table id={tableId} className={className} {...props}>
                              {children}
                            </table>
                            <button title="Export to Excel" id="btnExport" className="rounded" style={{backgroundColor: "rgb(39, 39, 39)", color: "white", marginTop:10}} onClick={() => TableToExcel.convert(document.querySelector(tableId))}><RiFileExcel2Line></RiFileExcel2Line></button>
                          </div>*/
                      );
                    },
                  }}
              />
          )}
        </div>
      </div>
    </div>
  );
});

  const CodeBlock = ({ language, value }) => {
    const codeRef = useRef();
    const [copied, setCopied] = useState(false);


    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    return (
        <div>
          <div className="codeHeader">
            <span className="codeLanguage">{language}</span>
            <button
                ref={codeRef}
                className="copyCodeBtn"
                onClick={copyToClipboard}
            >
              {copied ? (
                  <>
                    <FaCheck />
                    Copied!
                  </>
              ) : (
                  <>
                    <FiCopy />
                    Copy code
                  </>
              )}
            </button>
          </div>
          <SyntaxHighlighter language={language} style={dracula} className="codeBox">
            {value}
          </SyntaxHighlighter>
        </div>
    );
  };

  function ProtectedComponent() {
    const placeholderModalContent = PlaceholderModalContent();
    const modalContent = GetGuidelinesForUse();
    const {instance, inProgress, accounts} = useMsal();
    const [apiData, setApiData] = useState(null);

    const [question, setQuestion] = useState({});
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [history, setHistory] = useState([]);
    const textareaRef = useRef(null);
    const imageNumberRef = useRef(null);

    const scrollBottomBtnRef = useRef(null);

    const [historyCursor, setHistoryCursor] = useState(-1);


    const [hasNewLine, setHasNewLine] = useState(false);

    const endOfMessageRef = useRef(null);

    // Add a state variable to control the visibility of the sidebar
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [promptSubmitted, setPromptSubmitted] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    // Add a state variable to control the visibility of the scroll to bottom button
    const [showScrollButton, setShowScrollButton] = useState(false);

    const [showContinueButton, setShowContinueButton] = useState(false);
    const [showStopButton, setShowStopButton] = useState(false);

    const [stopGenerating, setStopGenerating] = useState(false);
    const stopGeneratingRef = useRef(false);

    // Function to toggle the sidebar visibility
    const toggleSidebar = () => {
      setSidebarVisible(!sidebarVisible);
    }

    const handleChange = (event) => {
      setInput(event.target.value);
      autoResizeTextarea();
    };


    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        if (!e.shiftKey && !hasNewLine) {
          e.preventDefault();
          SubmitQuestion(e);
        } else {
          setHasNewLine(true);
        }
      }


      // Filter messages array to only include user messages

      if (e.key === 'ArrowUp') {
        const userMessages = messages.filter((message) => message.role !== 'assistant');
        // Move up in user message history
        if (historyCursor < userMessages.length - 1) {
          setHistoryCursor((prevCursor) => prevCursor + 1);
          setInput(userMessages[userMessages.length - historyCursor - 2].content);
        }
      } else if (e.key === 'ArrowDown') {
        const userMessages = messages.filter((message) => message.role !== 'assistant');
        // Move down in user message history
        if (historyCursor > 0) {
          setHistoryCursor((prevCursor) => prevCursor - 1);
          setInput(userMessages[userMessages.length - historyCursor].content);
        } else if (historyCursor === 0) {
          // Clear the input when reaching the end of the history
          setHistoryCursor(-1);
          setInput('');
        }
      }

    };


    const autoResizeTextarea = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
        if(scrollBottomBtnRef.current){
          if(textareaRef.current.scrollHeight <= 200){
            scrollBottomBtnRef.current.style.bottom = (100 + textareaRef.current.scrollHeight) + "px";
          }else{
            scrollBottomBtnRef.current.style.bottom = "300px";
          }
        }
      }
    };

    const scrollToBottom = () => {
      endOfMessageRef.current?.scrollIntoView({behavior: 'smooth'});
    };


    // Get and store the account ID for consent
    const [localAccountDetails, setLocalStorageAccountDetails] = useState(() => {
      let localAccountId = "";
      let username = "";
      if (accounts && accounts.length > 0) {
        localAccountId = accounts[0].localAccountId;
        username = accounts[0].username;
        SetLocalStorageAccountDetails(localAccountId, username);
      }
    });

    GetConsent();

    const [userFullName, setUserFullName] = useState(() => {
      let userFullName = "";
      if(accounts && accounts.length > 0){
        userFullName = accounts[0].name;
      }
      const nameParts = userFullName.split(' '); // Assuming the format is "lastName, firstName"
      const lastName = nameParts[0].trim();
      const firstName = nameParts[1].trim();
      const parsedName = {
        firstName: firstName,
        lastName: lastName
      };

      return parsedName || [];
    });

    // CHAT TOPICS

    const [chatTopics, setChatTopics] = useState(() => {
      // getting stored value
      const saved = localStorage.getItem("chatTopics");
      const initialValue = JSON.parse(saved);
      return initialValue || [];
    });


    useEffect(() => {
      // storing input name
      localStorage.setItem("chatTopics", JSON.stringify(chatTopics));
    }, [chatTopics]);


    const [currentTopicId, setCurrentTopicId] = useState((null));

    const currentTopicIdRef = useRef(currentTopicId);
    useEffect(() => {
      currentTopicIdRef.current = currentTopicId;
    }, [currentTopicId]);


    const addChatTopic = (topicTitle) => {
      const newTopicId = Date.now();
      setCurrentTopicId(() => newTopicId);
      const initialMessage = {
        content: input,
        role: "user",
      };
      setChatTopics((prevChatTopics) => [
        ...prevChatTopics,
        {id: newTopicId, title: topicTitle, messages: [], modelName: currentModel},
      ]);

      setMessages([]);

    };

    const switchChatTopic = (topicId) => {

      // Set the current topic ID
      setCurrentTopicId(topicId);
      setPromptSubmitted(false);
      // Find the topic with the matching ID
      const selectedTopic = chatTopics.find((topic) => topic.id === topicId);

      // Update the messages state with the messages from the selected topic
      if (selectedTopic) {
        setMessages(selectedTopic.messages);
        if (selectedTopic.modelName) {
          setCurrentModel(selectedTopic.modelName); // Update the current model
        } else {
          setCurrentModel("gpt-4o");
        }
        setModelSelectionVisible(false); // Hide the model selection list
      } else {
        console.error("No topic found with the given ID:", topicId);
      }
      setSidebarVisible(false);
      textareaRef.current.focus();
    };

    const updateChatTopicTitle = (topicId, title) => {
      setChatTopics((prevChatTopics) =>
          prevChatTopics.map((topic) =>
              topic.id === topicId ? {...topic, title} : topic
          )
      );
    };

    const startNewChatTopic = () => {
      setCurrentTopicId(null);

      setCurrentModel("gpt-4o");
      setModelSelectionVisible(true);

      setMessages([]);
      setSidebarVisible(false);
      textareaRef.current.focus();
    };


    // Add state variables for edit mode and edited title
    const [editingTopicId, setEditingTopicId] = useState(null);
    const [editedTitle, setEditedTitle] = useState('');

    // Handle edit, save, cancel, and delete actions
    const handleEditClick = (topicId) => {
      const topic = chatTopics.find((topic) => topic.id === topicId);
      if (topic) {
        setEditingTopicId(topicId);
        setEditedTitle(topic.title);
      } else {
        console.error(`Topic with id ${topicId} not found.`);
      }
    };

    const handleSaveClick = (topicId) => {
      // Create a copy of the chatTopics array
      const updatedChatTopics = [...chatTopics];

      // Find the index of the topic you want to update
      const topicIndex = updatedChatTopics.findIndex((topic) => topic.id === topicId);

      // Update the title of the topic at that index
      if (topicIndex !== -1) {
        updatedChatTopics[topicIndex] = {
          ...updatedChatTopics[topicIndex],
          title: editedTitle,
        };

        // Update the chatTopics state with the modified array
        setChatTopics(updatedChatTopics);
      } else {
        console.error(`Topic with id ${topicId} not found.`);
      }

      setEditingTopicId(null);
      setEditedTitle('');
    };

    const handleCancelClick = () => {
      // Reset the editing state
      setEditingTopicId(null);
      setEditedTitle('');
    };

    const handleDeleteClick = (topicId) => {

      setMessages([]);
      // Filter the chatTopics array to exclude the topic with the specified topicId
      const updatedChatTopics = chatTopics.filter((topic) => topic.id !== topicId);
      setChatTopics(updatedChatTopics);


    };


    // END CHAT TOPICS


    useEffect(() => {
      const container = document.querySelector('.main-container');
      const scrollButton = document.querySelector('.scrollButton');

      const handleScroll = () => {
        const container = document.querySelector('.main-container');
        const isAtBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 20;
        setShowScrollButton(!isAtBottom);
      };

      container.addEventListener('scroll', handleScroll);
      // Check scroll position when messages change
      handleScroll();
      return () => container.removeEventListener('scroll', handleScroll);
    }, [messages]);

    useEffect(() => {
      function handleChatClick() {
        if (sidebarVisible) {
          setSidebarVisible(false);
        }
      }

      const chatContainer = document.querySelector('.chat-container');
      chatContainer.addEventListener('click', handleChatClick);

      return () => {
        chatContainer.removeEventListener('click', handleChatClick);
      };
    }, [sidebarVisible]);

    const addBotMessage = async (botCompletionTxt, chunkIndex = 0) => {
      if (!botCompletionTxt){
        botCompletionTxt = "";
      };

      // If it's the first chunk, create a new assistant message with an empty content
      if (chunkIndex === 0) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { content: '', role: 'assistant' },
        ]);
      }

      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];

        // Update the last assistant message with the new chunk
        if (lastMessage.role === 'assistant') {
          return prevMessages.map((message, index) => {
            if (index === prevMessages.length - 1) {
              return {
                ...message,
                content: message.content + botCompletionTxt,
              };
            }
            return message;
          });
        } else {
          return prevMessages;
        }
      });

      setTimeout(() => {
        updateChatTopics(botCompletionTxt, chunkIndex);
        updateHistory(botCompletionTxt, chunkIndex);
      }, 500);

    };


    const updateChatTopics = (botCompletionTxt, chunkIndex) => {
      setChatTopics((prevChatTopics) =>
          prevChatTopics.map((topic) => {
            if (topic.id === currentTopicIdRef.current) {
              if (chunkIndex === 0) {
                return {
                  ...topic,
                  messages: [
                    ...topic.messages,
                    { content: input, role: "user" },
                    { content: botCompletionTxt, role: "assistant" },
                  ],
                };
              } else {
                const updatedMessages = topic.messages.map((message, index) => {
                  if (
                      index === topic.messages.length - 1 &&
                      message.role === "assistant"
                  ) {
                    return {
                      ...message,
                      content: message.content + botCompletionTxt,
                    };
                  }
                  return message;
                });

                return { ...topic, messages: updatedMessages };
              }
            }
            return topic;
          })
      );
    };

    const updateHistory = (botCompletionTxt, chunkIndex) => {
      if (chunkIndex === 0) {
        setHistory((prevHistory) => [
          ...prevHistory,
          { role: "assistant", content: botCompletionTxt },
        ]);
      }

      setHistory((prevHistory) => {
        const lastMessage = prevHistory[prevHistory.length - 1];

        if (lastMessage.role === "assistant") {
          return prevHistory.map((message, index) => {
            if (index === prevHistory.length - 1) {
              return {
                ...message,
                content: message.content + botCompletionTxt,
              };
            }
            return message;
          });
        } else {
          return prevHistory;
        }
      });
    };

    const SubmitQuestion = (e) => {
      e.preventDefault();
      if (!input || submitButtonDisabled) return;

      if(["gpt-4o", "gpt-4-32k", "gpt-35-turbo", "dall-e-3"].includes(currentModel)){
        SubmitNSQuestion();
        return;
      }

      setSubmitButtonDisabled(true);
      setModelSelectionVisible(false); // Hide the model selection list
      setShowContinueButton(false);
      setShowStopButton(true);


      setPromptSubmitted(true);
      let question = sessionStorage.getItem('question');
      sessionStorage.setItem('question', "");


      if (input != "") {


        if (currentTopicId === null) {
          addChatTopic(input.substring(0, 100));
        }

        const newMessage = {
          content: input,
          role: "user"
        };
        setMessages([...messages, newMessage]);

        setHistory((prevHistory) => [
          ...prevHistory,
          {role: "user", content: input},
        ]);


        setIsLoading(true);
        let json;
        if (localAccountDetails) {
          // add insights book system message
          let systemString = `
                          •	The user you are chatting with has oid ${GetLocalStorageAccountDetails("localAccountId")}. 
                          `;
          systemString = ""; // clear this for now
          const systemMessage = {
            content: systemString,
            role: "system"
          };
          json = JSON.stringify({"Query": input, "ReqType": currentModel, History: [systemMessage, ...messages]});
        } else {
          json = JSON.stringify({"Query": input, "ReqType": currentModel, History: messages});
        }

        if (currentModel === "dall-e-3") {
          let imageCount = "1";
          if (imageNumberRef.current) {
            imageCount = imageNumberRef.current.value;
          }
          json = JSON.stringify({
            "prompt": input,
            "ReqType": currentModel,
            "n": parseInt(imageCount),
            "size": "1024x1024"
          });
        }

        const accessTokenRequest = {
          scopes: [window._env_.REACT_APP_AAD_SCOPE],
          account: accounts[0],
        };
        if (!apiData && inProgress === InteractionStatus.None) {
          instance
              .acquireTokenSilent(accessTokenRequest)
              .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;

                let chatTopic;
                if(currentTopicId === null) {
                  chatTopic = JSON.parse(localStorage.getItem("chatTopics"))[0].id
                } else {
                  chatTopic = currentTopicId
                }
                switchChatTopic(chatTopic);

                let source = new SSE(window._env_.REACT_APP_API_ENDPOINT,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        "x-api-key": window._env_.REACT_APP_API_KEY,
                        "Authorization": 'Bearer ' + accessToken
                      },
                      payload: json,
                      method: 'POST'
                    });
                let chunkIndex = 0;
                let lastMessage = "";
                let didNotFinish = false;
                source.addEventListener('message', function(e) {
                  let jsonChunk;

                  try {
                    if(e.data != "[DONE]" && e.data != "[CLOSED]"){
                      jsonChunk = JSON.parse(e.data);
                      try {
                        addBotMessage(jsonChunk.choices[0].delta.content, chunkIndex);
                        if(jsonChunk.choices[0].finish_reason == "length"){
                          didNotFinish = true;
                        }
                      } catch {
                        addBotMessage("", chunkIndex);
                      }
                      chunkIndex += 1;
                    }
                  }
                  catch (error) {
                    console.error("Parsing error:", error);
                    console.log("Content:", e.data);
                  }



                  if(e.data === "[DONE]" || stopGeneratingRef.current){
                    lastMessage = e.data;
                    // message is completed
                    setIsLoading(false);
                    setSubmitButtonDisabled(false);
                    setShowStopButton(false);
                    if(stopGeneratingRef.current){
                      stopGeneratingRef.current = false;
                      source.close();
                      setShowStopButton(false);
                    }
                    if(e.data === ""){
                      addBotMessage("An error has occurred answering your request.", chunkIndex);
                      chunkIndex += 1;
                    }
                    return false;
                  }

                  if(e.data === "[CLOSED]"){
                    // message is completed
                    setIsLoading(false);
                    setSubmitButtonDisabled(false);
                    setShowStopButton(false);

                    if(lastMessage != "[DONE]" || didNotFinish == true){
                      // then the stream was cut short due to a token max being hit
                      setShowContinueButton(true);
                    }

                    return false;
                  }

                  //user pressed stop
                  lastMessage = e.data;
                });




                source.stream();


                source.onerror = function (e) {
                  console.error('SSE error:', e);
                  setIsLoading(false);
                  setSubmitButtonDisabled(false);
                  addBotMessage('Something went wrong. Please try again later.');
                };


              })
              .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenRedirect(accessTokenRequest);
                }
                addBotMessage("I'm sorry, I'm not feeling well at the moment. It seems I've run into an issue: " + error);
                setSubmitButtonDisabled(false);
                console.log(error);
              });


        }



      }

      // Reset Text Area
      setInput('');
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }
      setHasNewLine(false);
      autoResizeTextarea();
      setHistoryCursor(-1);


    }


// BEGIN NON STREAMING REQUESTS
    const SubmitNSQuestion = (e) => {
      if (!input || submitButtonDisabled) return;

      setSubmitButtonDisabled(true);
      setModelSelectionVisible(false); // Hide the model selection list
      setPromptSubmitted(true);
      let question = sessionStorage.getItem('question');
      sessionStorage.setItem('question', "");

      if (input != "") {
        if (currentTopicId === null) {
          addChatTopic(input.substring(0, 100));
        }

        const newMessage = {
          content: input,
          role: "user"
        };

        setMessages([...messages, newMessage]);
        setHistory((prevHistory) => [
          ...prevHistory,
          { role: "user", content: input },
        ]);

        setIsLoading(true);
        // apply system message
        let json;
        if(userFullName){
          // add insights book system message
          let systemString = `The user you are chatting with is named ${userFullName.firstName} ${userFullName.lastName}.`;
          systemString = ""; // clear this for now
          const systemMessage = {
            content: systemString,
            role: "system"
          };
          json = JSON.stringify({ "Query": input, "ReqType":currentModel,  History:[systemMessage,...messages] });
        } else{
          json = JSON.stringify({ "Query": input, "ReqType":currentModel, History:messages });
        }

        let reqTokens = 0;

        //guess how many tokens are being used for request using a tokenizer. not 100% right but close
        messages.forEach(item => {
          const tokens = encode(item.content);
          reqTokens = reqTokens + tokens.length;
        });
        const pTokens = encode(input);
        reqTokens = reqTokens + pTokens.length;

        reqTokens = 4096 - reqTokens;

        // Recreate the json message one last time ... this is for the Mule port
        json = {
          messages: [
            ... (messages.map(item => ({
              role: item.role,
              content: [
                {
                  type: "text",
                  text: item.content
                }
              ]
            }))),
            {
              role: "user",
              content: [
                {
                  type: "text",
                  text: input
                }
              ]
            }
          ],
          temperature: 0.7,
          top_p: 0.95,
          max_tokens: reqTokens
        };

        if(currentModel === "dall-e-3"){
          let imageCount = "1";
          if (imageNumberRef.current) {
            imageCount = imageNumberRef.current.value;
          }
          json = JSON.stringify({ "prompt": input, "n":parseInt(imageCount), "size":"1024x1024"});
        }

        const accessTokenRequest = {
          scopes: [window._env_.REACT_APP_AAD_SCOPE],
          account: accounts[0],
        };
        if (!apiData && inProgress === InteractionStatus.None) {
          instance
              .acquireTokenSilent(accessTokenRequest)
              .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;

                if(currentModel === "dall-e-3") {
                  nsEndpoint = window._env_.REACT_APP_MULE_DALLE_ENDPOINT;
                } else {
                    nsEndpoint = window._env_.REACT_APP_MULE_CHATGPT_ENDPOINT;
                }

                nsEndpoint = nsEndpoint + "?DeploymentModel=" + currentModel;

                axios.post(nsEndpoint, json, {
                  headers: {
                    "Content-Type": "application/json"
                  }
                })
                    .then(response => {

                      if (response.status == 200) { // if everything came back OK
                        if (currentModel === "dall-e-3") {
                          let urls = "";
                          if(typeof(response.data.data) != "undefined") {
                            response.data.data.forEach((item) => {
                              urls += item.url.toString() + "\n";
                            });
                          }
                          addBotMessage(urls);
                        } else {
                          addBotMessage(response.data.choices[0].message.content.toString());
                        }
                      } else {
                        // Houston, we have a problem.
                        addBotMessage(response.data.body.message);
                      }

                      setIsLoading(false);
                      setSubmitButtonDisabled(false);
                    });


              })
              .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenRedirect(accessTokenRequest);
                }
                addBotMessage("I'm sorry, I'm not feeling well at the moment. It seems I've run into an issue: " + error);
                setSubmitButtonDisabled(false);
                console.log(error);
              });
        }


      }

      // Reset Text Area
      setInput('');
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }
      setHasNewLine(false);
      autoResizeTextarea();
      setHistoryCursor(-1);


    }
    // END NON STREAMING Requests

    const headerStyle = {
      display: 'flex'
    };

    const buttonStyle = {

      backgroundColor: '#09215B',
      color: 'white'
    };

    const micButtonStyle = {

      backgroundColor: '#09215B',
      color: 'white'
    };

    const micButtonStyleActive = {

      backgroundColor: 'red',
      color: 'white'
    };


    const footerStyle = {

      fontSize: '.6rem',
      color: 'black',
      textAlign: 'center',
    };

    const footerPriceStyle = {
      textAlign: 'center',
      fontSize: '.6rem',
      color: 'red'
    };

    /* SPEECH TO TEXT CODE */
    const promptSubmitBtn = useRef(null);
    const [listening, setListening] = useState(false);

    const handleStartListening = () => {
      const recognition = new window.webkitSpeechRecognition();
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        const result = event.results[event.results.length - 1];
        if (result.isFinal) {
          setInput(result[0].transcript);
        }
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event);
        setListening(false);
      };

      recognition.onend = () => {
        setListening(false);
        if (promptSubmitBtn.current) {
          promptSubmitBtn.current.click();
        }
      };

      recognition.start();
      setListening(true);
    };

    const handleStopListening = () => {
      if ('webkitSpeechRecognition' in window) {
        const recognition = new window.webkitSpeechRecognition();
        recognition.stop();
      }
      setListening(false);
    };

    const toggleListening = (e) => {
      e.preventDefault();
      if (listening) {
        handleStopListening();
      } else {
        handleStartListening();
      }
      return false;
    };

    /* END SPEECH TO TEXT */

    /* Model Switching */
    const [currentModel, setCurrentModel] = useState('gpt-4o');
    const modelChange = (event) => {
      setCurrentModel(event.target.value);
    };


    const getSelectedOptionCost = (selectedModel) => {
      const selectedButton = modelButtons.find(button => button.value === selectedModel);
      if (selectedButton) {
        return selectedButton.cost;
      }

      for (const button of modelButtons) {
        if (button.dropdownOptions) {
          const selectedOption = button.dropdownOptions.find(option => option.value === selectedModel);
          if (selectedOption) {
            return selectedOption.cost;
          }
        }
      }

      return null;
    };

    useEffect(() => {
      // Code to update footerPriceElement whenever currentModel changes
      updateFooterPrice(getSelectedOptionCost(currentModel), currentModel);
    }, [currentModel]);

    const updateFooterPrice = (priceText, selectedModel) => {
      // Code to update footerPriceElement
      const footerPriceElement = document.querySelector('.footerPriceStyle');
      if (footerPriceElement) {
        footerPriceElement.textContent = priceText;
      }

      const textAreaElement = document.querySelector('.message-input');

      if (selectedModel === "dall-e-3") {
        //change interface
        textAreaElement.placeholder = "Enter an image description (e.g., painting of a vineyard at sunset)...";

        const elements = document.querySelectorAll('.help-bubble');
        elements.forEach(element => {
          element.style.display = 'none';
        });

        const elements2 = document.querySelectorAll('.help-bubble-dalle');
        elements2.forEach(element => {
          element.style.display = 'block';
        });

        //reset interface
        updateAppNameSpan(selectedModel);
        autoResizeTextarea();
      } else {

        const elements = document.querySelectorAll('.help-bubble');
        elements.forEach(element => {
          element.style.display = 'block';
        });

        const elements2 = document.querySelectorAll('.help-bubble-dalle');
        elements2.forEach(element => {
          element.style.display = 'none';
        });

        //reset interface
        updateAppNameSpan(selectedModel);
        autoResizeTextarea();

        textAreaElement.placeholder = "Send a message...";
      }


    };

    const [modelSelectionVisible, setModelSelectionVisible] = useState(true);
    const modelButtons = [
      { name: 'ChatGPT', value:'gpt', icon: <BsStars size='1em' />, dropdown:true, desc:GPT4O_DESCRIPTION,
        dropdownOptions: [
          { name: 'ChatGPT 4-o', value:'gpt-4o', icon: <BsStars size='1em' />, desc:GPT4O_DESCRIPTION},
          { name: 'ChatGPT 4 32k', value:'gpt-4-32k', icon: <BsStars size='1em' />, desc:GPT432K_DESCRIPTION},
          { name: 'ChatGPT 3.5 Turbo', value:'gpt-35-turbo', icon: <BsFillLightningChargeFill size='1em' />, desc:GPT35_DESCRIPTION },
        ]
      },
      {
        name: 'DALL-E 3',
        value: 'dall-e-3',
        icon: <FaPaintBrush size='1em'/>,
        dropdown: false,
        desc: DALLE3_DESCRIPTION,
        dropdownOptions: []
      }
    ];


    let selectedModelObj = {};
    modelButtons.some(button => {
      let overriddenOptionValue;
      if(currentModel === "gpt") {
        overriddenOptionValue = "gpt-4o";
      } else if(currentModel === "dall-e") {
        overriddenOptionValue = "dall-e-3";
      }
      if (button.dropdownOptions) {
        const selectedOption = button.dropdownOptions.find(option => option.value === currentModel);
        if (selectedOption) {
          selectedModelObj = overriddenOptionValue ? overriddenOptionValue : selectedOption;
          return true; // Exit the loop when a match is found in dropdownOptions
        }
      }
      if (button.value === currentModel) {
        selectedModelObj = overriddenOptionValue ? overriddenOptionValue : button;
        return true; // Exit the loop when a match is found in modelButtons
      }
      return false;
    });

    const modelButtonText = (
        <>
          {selectedModelObj.icon} {selectedModelObj.name}
        </>
    );

    const handleStopButtonClick = () => {
      stopGeneratingRef.current = true;
      setShowStopButton(false);
    };

    const handleContinueButtonClick = () => {
      // Hide the "Continue" button
      setShowContinueButton(false);
      setShowStopButton(true);

      // Send the "Continue" message to the API
      const json = JSON.stringify({ "Query": "Continue", "ReqType": currentModel, History: messages });

      const accessTokenRequest = {
        scopes: [window._env_.REACT_APP_AAD_SCOPE],
        account: accounts[0],
      };

      if (!apiData && inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
              let accessToken = accessTokenResponse.accessToken;

              let source = new SSE(window._env_.REACT_APP_API_ENDPOINT, {
                headers: {
                  "Content-Type": "application/json",
                  "x-api-key": window._env_.REACT_APP_API_KEY,
                  "Authorization": 'Bearer ' + accessToken
                },
                payload: json,
                method: 'POST'
              });

              let chunkIndex = 1;

              source.addEventListener('message', function (e) {
                if (e.data === "[DONE]" || e.data === "[CLOSED]" || stopGeneratingRef.current) {
                  // Message is completed or the stream was closed
                  source.close();
                  stopGeneratingRef.current = false;
                  setShowStopButton(false);
                  return;
                }

                let jsonChunk = JSON.parse(JSON.stringify(e.data));

                // Update the last assistant message with the new chunk without adding the "Continue" message to the chat history
                if (jsonChunk.choices[0].delta.content) {
                  addBotMessage(((chunkIndex == 1) ? "" : "") + jsonChunk.choices[0].delta.content, chunkIndex);
                  chunkIndex += 1;
                }
              });

              source.onerror = function (e) {
                console.error('SSE error:', e);
                setIsLoading(false);
                setSubmitButtonDisabled(false);
                addBotMessage('Something went wrong. Please try again later.');
              };
              source.stream();

            })
            .catch((error) => {
              if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
              }
              addBotMessage("I'm sorry, I'm not feeling well at the moment. It seems I've run into an issue: " + error);
              setSubmitButtonDisabled(false);
              console.log(error);
            });
      }
    };

    return (
        <div className="appContDiv">
          <div id="declinedGuidelinesForUseModal" className="modal">
            <div className="modal-content">
              <div style={{fontSize: 'xx-large', textAlign: 'center'}}>To use ChatGPT you must accept the guidelines for
                use.
              </div>
              <div className="modal-content-buttons">
                <button className="modal-button" onClick={function () {
                  window.location = "https://www.g3enterprises.com/";
                }}>Exit
                </button>
                <button className="modal-button" onClick={function () {
                  const promptedGuidelinesForUseModal = document.getElementById("promptedGuidelinesForUseModal");
                  const declinedGuidelinesForUseModal = document.getElementById("declinedGuidelinesForUseModal");
                  declinedGuidelinesForUseModal.style.display = "none";
                  promptedGuidelinesForUseModal.style.display = "block";
                  DisableTabbingOutsideOfConsentModals();
                }}>Return
                </button>
              </div>
            </div>
          </div>

          <div id="promptedGuidelinesForUseModal" className="modal">
            <div className="modal-content">
              <Markup emptyContent={placeholderModalContent} content={modalContent}/>
              <div className="modal-content-buttons">
                <div>
                  <button className="modal-button" id="declineButton" onClick={function() {
                    const promptedGuidelinesForUseModal = document.getElementById("promptedGuidelinesForUseModal");
                    const declinedGuidelinesForUseModal = document.getElementById("declinedGuidelinesForUseModal");
                    promptedGuidelinesForUseModal.style.display = "none";
                    declinedGuidelinesForUseModal.style.display = "block";
                    DisableTabbingOutsideOfConsentModals();
                  }}>Decline</button>
                </div>
                <div>
                  <button className="modal-button" id="acceptButton" onClick={function () {
                    const promptedGuidelinesForUseModal = document.getElementById("promptedGuidelinesForUseModal");
                    promptedGuidelinesForUseModal.style.display = "none";
                    DisableTabbingOutsideOfConsentModals();
                    SetConsent();
                  }}>Accept</button>
                </div>
              </div>
            </div>
          </div>

          <div id="viewGuidelinesForUseModal" className="modal">
            <div className="modal-content">
              <Markup emptyContent={placeholderModalContent} content={modalContent}/>
              <div className="modal-content-buttons">
                <div>
                  <button className="modal-button" onClick={function () {
                    const viewGuidelinesForUseModal = document.getElementById("viewGuidelinesForUseModal");
                    viewGuidelinesForUseModal.style.display = "none";
                  }}>Close</button>
                </div>
              </div>
            </div>
          </div>
          <aside className={`sidebar ${sidebarVisible ? 'visible' : 'hidden'}`}>
            <div className="sidebarHeader">

              <img src="ChatGPTLogo.png"/>
            </div>
            <button
                className="addChatBtn relative inline-flex justify-start items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:#5D6B83 focus:z-10 bg-gray-200 rounded-l-md"
                onClick={startNewChatTopic}><FiPlus className="plus-icon"/><span>New chat</span></button>

            <div className="messageTopics">
              {chatTopics
                  .sort((a, b) => b.id - a.id)
                  .map((topic) => {
                    const isEditing = editingTopicId === topic.id;
                    return (
                        <div className="topicDiv" key={topic.id}>
                          <a

                              className={`messageTopic ${topic.id === currentTopicId ? 'topicSelected' : ''}`}
                              onClick={() => {
                                if (!isEditing) {
                                  switchChatTopic(topic.id);
                                }
                              }}
                          >
                            <FiMessageSquare/>
                            <div className="messageTopicContent">
                              {isEditing ? (
                                  <input autoFocus
                                         className="text-black"
                                         value={editedTitle}
                                         onChange={(e) => setEditedTitle(e.target.value)}
                                  />
                              ) : (
                                  topic.title
                              )}
                              <div className="messageTopicFade"></div>
                            </div>
                          </a>
                          {topic.id === currentTopicId && (
                              isEditing ? (
                                  <>
                                    <FaCheck className="topicLeftButton" onClick={() => handleSaveClick(topic.id)}/>
                                    <FaTimes className="topicRightButton" onClick={handleCancelClick}/>
                                  </>
                              ) : (
                                  <>
                                    <FaRegEdit className="topicLeftButton" onClick={() => handleEditClick(topic.id)}/>
                                    <FaRegTrashAlt className="topicRightButton"
                                                   onClick={() => handleDeleteClick(topic.id)}/>
                                  </>
                              )
                          )}
                        </div>
                    );
                  })}
            </div>


          </aside>
          <div className={`homeDiv ${sidebarVisible ? 'sidebar-visible' : ''}`}>
            <header className={` ${sidebarVisible ? 'header-visible' : ''}`}>
              <button onClick={toggleSidebar} className="sidebar-toggle">☰</button>
            </header>

            <main className="main-container">
              <button
                  style={{
                    position: 'absolute',
                    top: 7,
                    right: 21,
                    backgroundColor: "white",
                    borderRadius: 5,
                    color: "black",
                    zIndex: 999
                  }}
                  onClick={handleExportToPDF}
              >
                <AiOutlineFilePdf style={{fontSize: "1rem"}} title="Export to PDF"/>
              </button>

              <div className="chat-container">
                <div className={`mt-4 model-selection ${modelSelectionVisible ? 'visible' : 'hidden'}`}
                     style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <ModelSelector buttons={modelButtons} selected={currentModel} setSelected={setCurrentModel}/>
                </div>
                <div
                    className={`flex items-center justify-center gap-1 border-b border-black/10 bg-g3-blue p-3 text-white 
                  dark:border-gray-900/50 dark:bg-g3-blue dark:text-white model-name 
                  ${!modelSelectionVisible ? 'visible' : 'hidden'}`}>{modelButtonText}
                </div>
                {(!messages.length || messages.length == 0) && (
                    <div className="help-container">
                      <div className="help-header"><span className="appNameSpan">ChatGPT 4-o</span>
                        <div className="modelDescriptionSpan">{GPT4O_DESCRIPTION}</div>
                      </div>
                      <div className="help-bubble-heading"><CiBrightnessDown size={36} className="helpIcon"/>Examples
                      </div>
                      <div className="help-bubble-heading"><HiOutlineLightningBolt size={36} className="helpIcon"/>Capabilities
                      </div>
                      <div className="help-bubble-heading"><IoWarningOutline size={36} className="helpIcon"/>Limitations
                      </div>
                      <div className="help-bubble">"Write a compelling product description for packaging that is both
                        consumer and environmentally friendly."
                      </div>
                      <div className="help-bubble">Retains previous discussion for reference within an ongoing
                        conversation
                      </div>
                      <div className="help-bubble">May on occasion produce inaccurate information</div>
                      <div className="help-bubble">"Craft a blog post that explores the different types of logistics
                        transportation solutions that are available throughout California."
                      </div>
                      <div className="help-bubble">Allows feedback in subsequent prompts to provide follow-up
                        corrections
                      </div>
                      <div className="help-bubble">Limited knowledge of world events after certain dates, depending on the
                        model
                      </div>
                      <div className="help-bubble">"Write a social media post on wine cork sustainability emphasizing
                        Diam Corks."
                      </div>
                      <div className="help-bubble">Will decline to answer inappropriate requests</div>
                      <div className="help-bubble">Not currently trained to produce results on G3-owned data</div>

                      <div className="help-bubble-dalle">"Create an image of a vineyard with grapes that are shaped like a
                        circle"
                      </div>
                      <div className="help-bubble-dalle">Can generate images from textual descriptions.</div>
                      <div className="help-bubble-dalle">Not capable of understanding the context between prompts</div>
                      <div className="help-bubble-dalle">"Generate an image of a wine bottle and cheese tray"</div>
                      <div className="help-bubble-dalle">Can combine multiple objects into a single image</div>
                      <div className="help-bubble-dalle">Limited to generating static images and cannot create animations
                        or videos
                      </div>
                      <div className="help-bubble-dalle">"Generate an image of a picnic at the beach"</div>
                      <div className="help-bubble-dalle">Can generate images with high resolution and detail</div>
                      <div className="help-bubble-dalle">All image generation prompts must comply with the guidelines for use</div>

                    </div>
                )}
                <Messages key={currentTopicId} messages={messages} isLoading={isLoading} endOfMessageRef={endOfMessageRef} promptSubmitted={promptSubmitted} isAtBottom={!showScrollButton} currentModel={currentModel} />
                {showContinueButton && (
                    <button
                        className="continue-button"
                        onClick={handleContinueButtonClick}
                    >
                      Continue
                    </button>
                )}
                {showStopButton && (
                    <button
                        className="continue-button"
                        onClick={handleStopButtonClick}
                    >
                      Stop Generating
                    </button>
                )}
                <div ref={endOfMessageRef}></div>
                {showScrollButton && (
                    <button
                        ref={scrollBottomBtnRef}
                        className="scrollButton"
                        onClick={scrollToBottom}
                    >
                      <FaArrowDown/>
                    </button>
                )}
              </div>
            </main>
            <footer>
              <form onSubmit={SubmitQuestion} className="input-form" style={{position: 'static'}}>

                <button className="rounded" style={listening ? micButtonStyleActive : micButtonStyle}
                        onClick={toggleListening} disabled={submitButtonDisabled}>
                  {listening ? <FaStop/> : <FaMicrophone/>}
                </button>

                <textarea
                    ref={textareaRef}
                    className="message-input" // Add the className property here
                    name="questionTxt"
                    value={input}
                    onChange={handleChange}
                    placeholder="Send a message..."
                    rows="1"
                    onKeyDown={handleKeyDown}
                />

                <button className="rounded-md ml-2" style={buttonStyle} type="submit" disabled={submitButtonDisabled}
                        key={currentTopicId} ref={promptSubmitBtn}>
                  {submitButtonDisabled ? <FiLoader/> : <FiSend/>}
                </button>
              </form>
              <div className="footerStyle">
                <a style={{textDecoration: "underline", fontWeight: "bold"}}
                   onClick={function () {
                     const viewGuidelinesForUseModal = document.getElementById("viewGuidelinesForUseModal");
                     viewGuidelinesForUseModal.style.display = "block";
                   }} style={{cursor: "pointer", textDecoration: "underline"}}>Guidelines for use.</a> ChatGPT may
                produce inaccurate information about people, places, or facts and is not currently trained to produce results on G3-owned data.
              </div>
            </footer>
          </div>
        </div>

    )
};

const TableBlock = ({children, className,...props }) => {
  const tbl = useRef(null);
  const onDownload = useCallback(() => {
    const currentDate = new Date();
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    };
    const currentDateTimeString = currentDate.toLocaleString('en-US', options).replace(/[,\/\s:]/g, '_');
    const elt = tbl.current;
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, "ChatGPT " + currentDateTimeString + ".xlsx");
  }, [tbl]);

  const tableId = "ID" + uuidv4();
  const onDownloadPPT = useCallback(() => {
    const currentDate = new Date();
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    };
    const currentDateTimeString = currentDate.toLocaleString('en-US', options).replace(/[,\/\s:]/g, '_');
    let pptx = new pptxgen();
    pptx.tableToSlides(tableId, {autoPage:true});
    pptx.writeFile({ fileName: "ChatGPT " + currentDateTimeString + ".pptx" });
  }, [tbl]);


  return (
      <div>

        <table ref={tbl} id={tableId} className={className} {...props}>
          {children}
        </table>
        <button title="Export to Excel" id="btnExport" className="rounded" style={{backgroundColor: "rgb(39, 39, 39)", color: "white", marginTop:10}} onClick={onDownload}><RiFileExcel2Line></RiFileExcel2Line></button> &nbsp;

      </div>
  );
};

const MarkdownMessage = ({ content }) => {
  return (
    <ReactMarkdown
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          let language = "";
          if (match) {
            language = match[1];
          }
          return !inline ? (
            <CodeBlock language={language} value={String(children).replace(/\n$/, '')} {...props} />
          ) : (
            <code className={{ className }} {...props}>
              {children}
            </code>
          );
        },
      }}
      remarkPlugins={[gfm]}
    >
      {content}
    </ReactMarkdown>
  );
};


// PDF Export
const handleExportToPDF = () => {
  // Get the target element by its ID or use a ref to the div element
  const element = document.getElementsByClassName('chat-container')[0];

  // Generate the current date and time string
  const currentDate = new Date().toLocaleDateString().replaceAll('/', '-');
  const currentTime = new Date().toLocaleTimeString().replace(/:/g, '-');
  const dateTime = `${currentDate}_${currentTime}`;
  let opt = {
    image: { type: 'jpeg' },
    html2canvas: { useCORS: true, scale: 2 },
    pagebreak: {
      mode: ['avoid-all', 'css', 'legacy']
    }
  };

  // Generate the PDF using html2pdf.js with the modified file name
  html2pdf().from(element).set(opt).save(`ChatGPTConversation_${dateTime}.pdf`);

};

function useDebounce(callback, delay) {
  const debounceRef = useRef(null);

  useEffect(() => {
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, []);

  const debouncedCallback = (...args) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  return debouncedCallback;
}

async function getUserProfile(accessToken) {
  const response = await fetch("https://graph.microsoft.com/v1.0/me", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch user profile");
  }
}

async function getUserAvatar(accessToken) {
  const response = await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.ok) {
    return await response.blob();
  } else {
    throw new Error("Failed to fetch user avatar");
  }
}

// WINDOW EVENTS
function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      const windowHeight = window.innerHeight;
      document.querySelector('.sidebar').style.height = windowHeight + "px"; // fix sidebar height issue on mobile when keyboard appears/disappears
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
}

function Home() {
  const windowWidth = useWindowWidth();

  const { login, result, error } = useMsalAuthentication("redirect");

  DisableTabbingOutsideOfConsentModals();

  // Set the title for the environment
  document.title = appTitle;

  return (
    <AuthenticatedTemplate>
      <ProtectedComponent />
    </AuthenticatedTemplate>
  );
}

export default Home;